import { useReport } from './hooks/use-context';
import { SectionData } from './types';

export function Table() {
  const { body } = useReport();

  const sections = body?.sections.filter(
    (section) => section.section_name !== 'Informações',
  );

  const gridStyles = {
    display: 'grid',
    gridTemplateColumns: '1fr 12fr 2fr 1fr 1fr 8fr 2.5fr 2.5fr',
  };

  const HeadColumns = ({
    label,
    isLast,
  }: {
    label: string;
    isLast?: boolean;
  }) => (
    <div
      style={{
        backgroundColor: '#d3d3d3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        borderRight: !isLast ? '1px #000 solid' : 'none',
        borderBottom: '1px #000 solid',
        fontSize: '9px',
      }}
    >
      {label}
    </div>
  );

  const HeadVerticalColumns = ({ label }: { label: string }) => (
    <div
      style={{
        backgroundColor: '#d3d3d3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRight: '1px #000 solid',
        borderBottom: '1px #000 solid',
        fontSize: '9px',
        lineHeight: '1px',
      }}
    >
      <p
        style={{
          transform: 'rotate(-90deg)',
          margin: 0,
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </p>
    </div>
  );

  const ValueCells = ({
    label,
    isLast,
  }: {
    label: string;
    isLast?: boolean;
  }) => (
    <div
      style={{
        backgroundColor: '#fff',
        padding: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        borderRight: '1px #000 solid',
        borderBottom: !isLast ? '1px #000 solid' : 'none',
        fontSize: '9px',
      }}
    >
      {label}
    </div>
  );

  const Section = ({
    section,
    isEven,
    isFirst,
    isLast,
  }: {
    section: SectionData;
    isEven?: boolean;
    isFirst?: boolean;
    isLast?: boolean;
  }) => {
    const orderedQuestions = section?.questions
      .sort((a, b) => {
        const extractNumber = (name: string) =>
          parseInt(name.match(/^\d+/)?.[0] || '0', 10);
        return extractNumber(a.name) - extractNumber(b.name);
      })
      .filter((question) => question.type === 'radio');

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(60, 1fr)',
          breakInside: !isFirst ? 'avoid' : undefined,
        }}
      >
        <div
          style={{
            height: orderedQuestions
              ? `${(orderedQuestions?.length || 4) * 80}px`
              : 'auto',
            width: 'auto',
            maxWidth: '30px',
            backgroundColor: isEven ? '#d3d3d3' : '#fff',
            borderRight: '1px #000 solid',
            borderBottom: '1px #000 solid',
            overflow: 'hidden',
            gridColumn: '1 / span 2',
          }}
        >
          <div
            style={{
              display: 'flex',
              transform: 'rotate(-90deg)',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              width: '100%',
              maxWidth: '30px',
              height: `${20 + section.section_name.length * 6}px`,
              margin: 0,
            }}
          >
            {section.section_name}
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridColumn: '3 / span 48',
          }}
        >
          {orderedQuestions?.map((question, index) => {
            const getText = () => {
              if (question.answers[0].option === 'Não Conforme') {
                const globalQuestionIndex = section?.questions.findIndex(
                  (item) => item.question_id === question.question_id,
                );

                if (section.questions[globalQuestionIndex + 1].type === 'text')
                  return section.questions[globalQuestionIndex + 1].answers[0]
                    .content;
              }
            };

            return (
              <div
                key={index}
                style={{
                  display: 'grid',
                  gridTemplateColumns: '24fr 4fr 2fr 2fr 16fr',
                }}
              >
                <ValueCells label={question.name} />
                <ValueCells
                  label={question.answers[0].option === 'Conforme' ? 'X' : ''}
                />
                <ValueCells
                  label={
                    question.answers[0].option === 'Não Conforme' ? 'X' : ''
                  }
                />
                <ValueCells label={`${question.weight}`} />
                <ValueCells
                  label={getText() || ''}
                />
              </div>
            );
          })}
        </div>
        <div
          style={{
            height: 'auto',
            width: 'auto',
            maxWidth: '100px',
            backgroundColor: '#fff',
            borderRight: '1px #000 solid',
            borderBottom: '1px #000 solid',
            overflow: 'hidden',
            gridColumn: '51 / span 5',
          }}
        >
          <div
            style={{
              display: 'flex',
              transform: 'rotate(-90deg)',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '100px',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              width: '100%',
              paddingRight: '40px',
              margin: 0,
            }}
          >
            {section?.percent_score}
          </div>
        </div>
        <div
          style={{
            height: 'auto',
            width: 'auto',
            backgroundColor: '#fff',
            borderBottom: isLast ? '1px #000 solid' : '',
            overflow: 'inherit',
            gridColumn: '56 / span 5',
          }}
        >
          <div
            style={{
              display: 'flex',
              transform: 'rotate(-90deg)',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              width: '100%',
              paddingRight: '40px',
              margin: 0,
              fontWeight: 'bold',
            }}
          >
            {isFirst ? body?.percent_score : ''}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {/* Headers */}
      <div style={gridStyles}>
        <div
          style={{
            backgroundColor: '#d3d3d3',
            borderRight: '1px #000 solid',
            borderBottom: '1px #000 solid',
            height: '30px',
          }}
        ></div>
        <HeadColumns label="Items" />
        <HeadColumns label="Conforme" />
        <HeadColumns label="NC" />
        <HeadVerticalColumns label="Peso" />
        <HeadColumns label="Observações" />
        <HeadColumns label="Parcial" />
        <HeadColumns label="Geral" isLast />
      </div>

      {sections.map((section, index) => (
        <Section
          section={section}
          isEven={index % 2 === 0}
          isFirst={index === 0}
          isLast={index === sections.length - 1}
        />
      ))}

      <div
        style={{
          padding: '4px',
          fontSize: '10px',
          borderBottom: '1px #000 solid',
        }}
      >
        <div style={{ fontWeight: 'bold' }}>Observações gerais:</div>

        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {
            body?.sections
              .find((section) => section.section_name === 'Outros...')
              ?.questions.find(
                (question) => question.name === 'Observações gerais:',
              )?.answers[0].content
          }
        </div>
      </div>
    </div>
  );
}
